<template>
  <div class="WorksRank">
    <div class="header">
      <!-- <div class="back" @click="$router.back()">返回</div> -->
      <div class="title">作品榜</div>
    </div>
    <div class="search_box">
      <div class="search">
        <div class="search_left">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <input
          class="inp"
          v-model="searchValue"
          type="text"
          placeholder="让每个人都写得更好"
          @keyup.enter="search"
        />
        <div class="search_btn" @click="search()">
          <img :src="require('assets/images/idiom.png')" alt="" />
          <img :src="require('assets/images/included_search.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="tableContent" v-if="tableData.length != 0">
      <div class="table_item" v-for="item in tableData" :key="item.id">
        <div class="rank">
          <img
            :src="require('assets/images/library/Thefirst.png')"
            v-if="item.rank == 1"
            alt=""
          />
          <img
            :src="require('assets/images/library/Thesecond.png')"
            v-if="item.rank == 2"
            alt=""
          />
          <img
            :src="require('assets/images/library/Thethird.png')"
            v-if="item.rank == 3"
            alt=""
          />
          <template>{{ item.rank > 3 ? item.rank : "" }}</template>
        </div>
        <div class="main">
          <div class="title">主标题:{{ item.filename }}</div>
          <div class="classfiy">{{ item.portfolioType || "暂无分类" }}</div>
          <div class="info">
            <div
              class="worker"
              @click="
                $router.push({
                  name: 'authorDetails',
                  params: {
                    userId: item.userId,
                  },
                })
              "
              style="color: #ff6900"
            >
              <img
                :src="
                  (item.headImg && item.headImg) ||
                  require('assets/images/personal-settings/user.png')
                "
                alt=""
              />
              作者: {{ item.author }}
            </div>
            <div class="time">
              创作时间:
              {{
                (item.publishTime && item.publishTime.substring(0, 10)) ||
                "暂无"
              }}
            </div>
            <div class="pirce">
              购买价格: {{ item.price || 0 }}
              {{ item.filetype == 1 && item.setPrice == 0 ? "字符" : "元" }}
            </div>
            <!-- <div class="pirce">
              授权价格: {{ item.authorizePrice || 0 }}
              {{ item.filetype == 1 && item.setPrice == 0 ? "字符" : "元" }}
            </div> -->
            <div class="cashIncome">收益: {{ item.cashIncome }}</div>
            <!-- <el-popover
              popper-class="option-popper"
              placement="bottom"
              trigger="click"
            >
              <div class="option_item" @click="detailsClick(item, index)">
                查看
              </div>
              <img
                class="more"
                slot="reference"
                :src="require('assets/images/headers/more.png')"
                alt=""
              />
            </el-popover> -->
          </div>
        </div>
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page="page"
        :page-size="limit"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <div class="kong" v-else>
      <img class="kong" :src="require('assets/images/kong.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { worksList } from "api/library";
export default {
  name: "WorksRank",
  data() {
    return {
      tableData: [],
      page: 1,
      limit: 10,
      pageTotal: 0,
      value: "1",
      searchValue: "",
      options: [
        {
          value: "1",
          label: "作者",
        },
        {
          value: "2",
          label: "主标题",
        },
      ],
    };
  },
  created() {
    this.getWorksList();
  },
  methods: {
    search() {
      this.getWorksList();
    },
    async getWorksList() {
      const data = {
        page: this.page,
        limit: this.limit,
        author: this.value == "1" ? this.searchValue : "",
        filename: this.value == "2" ? this.searchValue : "",
      };
      const res = await worksList(data);
      if (res.code == 200) {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        // console.log(res.data);
      }
    },
    currentChange(val) {
      this.page = val;
      this.getWorksList();
    },
    detailsClick(item, index) {
      // console.log(item, index);
      try {
        // eslint-disable-next-line
        OpenDocument2(
          item.filename,
          this.$store.state.onlineBasePath + item.url
        );
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.WorksRank {
  height: 100%;
  position: relative;
  flex: 1;
  .search_box {
    margin: 20px auto;
    width: 60%;
    padding: 0 20px;
    .search {
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      // padding: 5px 5px 5px 0;

      border-radius: 3px;
      border: 1px solid #ff6900;

      .search_left {
        .el-select {
          ::v-deep .el-input {
            .el-input__inner {
              width: 100px;
              border: 0px;
            }
          }
        }
      }

      .inp {
        height: 26px;
        border-left: 1px solid #e4e4e4;
        padding-left: 20px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-default;
        flex: 1;
      }
      .search_btn {
        height: 40px;
        padding: 0 10px;
        background: #ff6900;

        @include flex-center();
        // border-radius: 0 5px 5px 0;
        cursor: pointer;
        > img:first-child {
          width: 52px;
          height: 22px;
          margin: 0 10px;
        }
      }
    }
  }
  .header {
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    .back {
      position: absolute;
      left: 3%;
      top: 50%;
      transform: translateY(-50%);
      color: #999;
      font-size: 14px;
      cursor: pointer;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        transform: rotate(45deg);
      }
    }
    .title {
      // height: 60px;
      // line-height: 60px;
      font-weight: bold;
      text-align: center;
    }
  }
  .kong {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tableContent {
    padding: 10px;
    // height: calc(100vh - 122px);
    font-size: 14px;
    .table_item {
      position: relative;
      cursor: pointer;
      // padding-top: 10px;
      // box-shadow: 0px 0px 10px 2px #eee;
      border-bottom: 0.0625rem solid #efefef;
      margin: 6px 0;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      // border-bottom: 1px #ccc solid;
      // min-width: 650px;
      .rank {
        font-weight: bold;
        text-align: center;
        width: 70px;
        font-size: 18px;
        // font-size: 30px;
        img {
          width: 34px;
          height: 34px;
        }
      }
      // white-space: nowrap;
      &:hover {
        background: #ffffff;
        box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
        border-radius: 5px;
      }
      > img {
        width: 60px;
        height: 60px;
      }
      .main {
        width: 80%;
        flex: 1;
      }
      .states {
        display: inline-block;
        width: 50px;
        height: 18px;
        // margin-top: -9px;
        font-size: 12px !important;
        // font-weight: 800;
        text-align: center;
        line-height: 18px;
        color: #999;
        z-index: 1;
        position: absolute;
        left: 11px;
        bottom: 15px;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

      .gray {
        // background: url("~assets/images/rhombus-gray.png") no-repeat;

        color: #999;
      }
      .green {
        // background: url("~assets/images/rhombus-green.png") no-repeat;
        color: #4587ff;
      }
      .orange {
        // background: url("~assets/images/rhombus-orange.png") no-repeat;
        color: orange;
      }
      .eparsed {
        // width: 62px;
        color: rgb(192, 0, 0);
        // background: url("~assets/images/rhombus-red.png") no-repeat;
      }
      .hasparse {
        // width: 75px;
        color: rgb(192, 0, 0);
        // background: url("~assets/images/rhombus-red-long.png") no-repeat;
      }
      .title {
        width: 80%;
        // white-space: nowrap;
        font-weight: bold;
        overflow: hidden;

        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        .main-title {
          // margin-left: 20px;
          height: 30px;
          line-height: 30px;
        }

        // padding-left: 20px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        // font-weight: bold;

        // border-left: 3px solid #4587ff;
      }
      .classfiy {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 13px;
        // background-image: linear-gradient(to right, #bbb, gray);
        background-color: #ff6900;
        border-radius: 5px;
        color: #fff;
        padding: 5px;
      }
      .info {
        color: gray;
        // padding-left: 20px;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        white-space: nowrap;
        display: flex;
        .worker {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 150px;
          line-height: 40px;
          // vertical-align: middle;
          img {
            display: inline-block;
            width: 20px;
            vertical-align: middle;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        // color: #333333;
        // img {
        //   width: 50px;
        //   height: 50px;
        //   vertical-align: middle;
        // }
        div:nth-child(n) {
          text-align: left;
        }
        div {
          display: inline-block;
          width: 38%;
          text-align: center;
        }
        .more {
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
    ::v-deep .el-pagination {
      text-align: center;
    }
  }
}
</style>
